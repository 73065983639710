#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hidden {
  display: none;
}

.App {
  text-align: center;
}

#button {
    background-color: #009c31;
    padding: 60px;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: 40px;
    text-align: center;
    line-height: 250px; /* <-- this is what you must define */
    vertical-align: middle;
    font-size: 40px;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
